import { React, useRef } from "react";
import { MdOutlineCall, MdOutlineEmail } from "react-icons/md";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import "./Support.css";

function Support() {
  const footerRef = useRef(null);

  return (
    <>
      <div className="app">
        <div className="background">
          <div className="background-overlay"></div>
        </div>
        <div
          className="support-content page-content"
          onClick={() => footerRef.current.visibility(false)}
        >
          <Header />
          <div className="content">
            <h1>Support</h1>
            <h3 className="subtitle">How can we help you today?</h3>
            <div className="support-list">
              <button className="button">
                <div className="row">
                  <MdOutlineEmail /> team@shopmezo.com
                </div>
              </button>
              <button className="button">
                <div className="row">
                  <MdOutlineCall /> +1 (617) 775-7415
                </div>
              </button>
            </div>
          </div>
          <Footer ref={footerRef} />
        </div>
      </div>
    </>
  );
}

export default Support;
