import { React, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import IOSDownloadButton from "../../components/IOSDownloadButton/IOSDownloadButton";
import "./Home.css";

function LegalModal({ onClose }) {
  return (
    <>
      <div className="home-legal-modal">
        <p className="home-legal-modal-text">
          By using the Mezo website or app,
          <br /> you consent to our&nbsp;
          <a href="#privacy" className="home-legal-modal-link">
            privacy policy
          </a>{" "}
          and
          <br />
          <a href="#eula" className="home-legal-modal-link">
            terms of service (EULA)
          </a>
          .
        </p>
        <button className="home-legal-modal-button" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  );
}

function Home() {
  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    return () => {
      // This will run when the component is unmounted
      document.body.style.overflow = overflow;
    };
  }, []);

  const has_accepted_tos =
    localStorage.getItem("mezo-tos-modal-closed") !== null;

  const [showModal, setShowModal] = useState(!has_accepted_tos);
  const footerRef = useRef(null);

  return (
    <div className="app">
      <div className="background">
        <div className="background-overlay"></div>
      </div>

      <div
        className="page-content home-root"
        onClick={() => {
          footerRef.current.visibility(false);
        }}
      >
        <Header />

        <div className="home-content">
          <div className="home-headline">
            <h3 className="home-tagline">FIND YOUR FIT.</h3>
            <h6 className="home-subtitle">
              A seamless, algorithmic shopping experience.
            </h6>
          </div>
          {<IOSDownloadButton className="home-mobile-download-ios" />}
        </div>

        <Footer ref={footerRef} />
      </div>
      {showModal &&
        createPortal(
          <LegalModal
            onClose={() => {
              setShowModal(false);
              localStorage.setItem("mezo-tos-modal-closed", true);
            }}
          />,
          document.body,
        )}
    </div>
  );
}

export default Home;
