import { forwardRef, useImperativeHandle } from "react";
import { React, useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./Footer.css";

const Footer = forwardRef((props, ref) => {
  const [inFooter, setInFooter] = useState(false);

  const dark = props.dark !== undefined ? props.dark : false;

  useImperativeHandle(ref, () => ({
    visibility(vis) {
      setInFooter(vis);
    },
  }));

  // Fixes iOS scrolling in Safari
  //
  // The bug causes the page to scroll and jitter when swiping to raise the footer,
  // even though the page is the exact height of the viewport.
  document.ontouchmove = function (e) {
    e.preventDefault();
  };

  const disableScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };
  const enableScroll = () => {
    // Allow slide-down animation to finish before re-enabling scroll
    setTimeout(() => {
      window.onscroll = null;
    }, 300);
  };

  useEffect(() => {
    const footer = document.querySelector("footer");

    if (!footer) {
      return;
    }

    if (inFooter) {
      disableScroll();
      footer.classList.add("active");
      const rect = footer.getBoundingClientRect();
      footer.style.top = `calc(100vh - ${rect.height}px)`;
    } else {
      enableScroll();
      footer.classList.remove("active");
      footer.style.top = "100vh";
    }
  }, [inFooter]);

  useEffect(() => {
    // This will run when the component is mounted

    const scroll = (ev) => {
      ev.stopPropagation();
      const direction = ev.deltaY > 0 ? "down" : "up";

      const pageContent = document.querySelector(".page-content");

      // Don't trigger footer for small scroll motions
      if (
        // FIXME: This is causing issues on MacOS with trackpads.
        Math.abs(ev.deltaY) < 50 &&
        direction === "down"
      ) {
        return;
      }

      setInFooter(
        direction === "down" &&
          (pageContent === null ||
            window.scrollY + window.innerHeight >= pageContent.scrollHeight),
      );
    };

    let swiping, startY, dY;
    const touchStart = (ev) => {
      ev.stopPropagation();
      swiping = true;
      startY = ev.touches[0].clientY;
    };

    const touchMove = (ev) => {
      if (!swiping) {
        ev.stopPropagation();
        return;
      }
      dY = ev.touches[0].clientY - startY;
      if (Math.abs(dY) > 100) {
        setInFooter(dY < 0);
      }
    };

    const touchEnd = () => {
      swiping = false;
    };

    window.addEventListener("mousewheel", scroll, true);
    window.addEventListener("touchstart", touchStart, false);
    window.addEventListener("touchmove", touchMove, false);
    window.addEventListener("touchend", touchEnd, false);

    return () => {
      // This will run when the component is unmounted
      window.removeEventListener("mousewheel", scroll);
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", touchMove);
      window.removeEventListener("touchend", touchEnd);
    };
  }, []);

  const caret = (
    <div className="footer-caret-wrapper">
      <span
        className={dark ? "footer-caret dark" : "footer-caret"}
        onClick={(ev) => {
          ev.stopPropagation();
          setInFooter(true);
        }}
      >
        <MdKeyboardArrowDown />
      </span>
    </div>
  );

  return (
    <>
      {props.caret !== false ? caret : null}
      <footer className="footer">
        <a href="#privacy" className="nav">
          <p>Privacy Policy</p>
        </a>
        <a href="#EULA" className="nav">
          <p>EULA</p>
        </a>
        <a href="#support" className="nav">
          <p>Support</p>
        </a>
        <a href="mailto:team@shopmezo.com" className="nav">
          <p>Contact Us</p>
        </a>
        <p className="copyright">© 2024 Mezo</p>
      </footer>
    </>
  );
});

export default Footer;
