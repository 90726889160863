import React from "react";
import { logAnalyticEvent } from "../../services/firebase";
import Header from "../../components/Header/Header";

import "./Redirect.css";

function Redirect() {
  const params = new URLSearchParams(window.location.search);
  const source = params.get("source");
  logAnalyticEvent("web_redirect", {
    source: source,
  });

  // TODO: Provide this link from backend, don't hardcode.
  const appStoreUrl = "https://apps.apple.com/us/app/shop-mezo/id6471849096";
  window.location.href = appStoreUrl;

  return (
    <>
      <Header download={false} />

      <div className="redirect">
        <div className="background">
          <div className="background-overlay"></div>
        </div>
        <div className="page-content">
          <div className="container">
            <h3 className="subtitle">We are taking you to the App Store.</h3>
            <button
              className="button"
              onClick={() => {
                window.location.href = appStoreUrl;
              }}
            >
              Continue
            </button>
            <div className="redirect-continue-note">
              If you are not redirected, please click the button above.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Redirect;
