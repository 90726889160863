import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Privacy from "./pages/Privacy/Privacy";
import Support from "./pages/Support/Support";
import Redirect from "./pages/Redirect/Redirect";
import EULA from "./pages/EULA/EULA";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="support" element={<Support />} />
        <Route path="redirect" element={<Redirect />} />
        <Route path="eula" element={<EULA />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
