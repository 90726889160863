import { React } from "react";
import { ReactComponent as IosDownloadIconLight } from "../../assets/images/App_Store_Badge_White.svg";
import { ReactComponent as IosDownloadIconDark } from "../../assets/images/App_Store_Badge.svg";

function IOSDownloadButton(props) {
  const dark = props.dark !== undefined ? props.dark : false;

  return (
    <div className={props.className}>
      <a href="?source=landing#redirect">
        {dark ? <IosDownloadIconDark /> : <IosDownloadIconLight />}
      </a>
    </div>
  );
}

export default IOSDownloadButton;
