import { React } from "react";
import { ReactComponent as HeaderIcon } from "../../assets/images/logo.svg";
import IOSDownloadButton from "../IOSDownloadButton/IOSDownloadButton";

import "./Header.css";

function Header(props) {
  const dark = props.dark !== undefined ? props.dark : false;

  return (
    <header className={`header ${dark ? "header-dark" : ""}`}>
      <a className="header-logo-link" href="/">
        <HeaderIcon className="header-icon" />
        <h1 className="header-title">Mezo</h1>
      </a>
      {props.download !== false ? (
        <IOSDownloadButton className="header-download-ios" dark={dark} />
      ) : null}
    </header>
  );
}

export default Header;
