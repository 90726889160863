// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJfXUEuIex6Gj47Fl3bGm_-p4NAJRdORo",
  authDomain: "tbd-shopping.firebaseapp.com",
  projectId: "tbd-shopping",
  storageBucket: "tbd-shopping.appspot.com",
  messagingSenderId: "822061908486",
  appId: "1:822061908486:web:a89511440ee0366b7b3987",
  measurementId: "G-PXM5BFKGGC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logAnalyticEvent = (name, params) => {
  logEvent(analytics, name, params);
};
