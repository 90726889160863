import { React, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

function EULA() {
  const footerRef = useRef(null);

  return (
    <>
      <Header dark />
      <div
        className="eula page-content"
        onClick={() => footerRef.current.visibility(false)}
      >
        <h2>End-User License Agreement (EULA) of Mezo</h2>

        <p>
          This End-User License Agreement ("EULA") is a legal agreement between
          you and Mezo
        </p>

        <p>
          This EULA agreement governs your acquisition and use of our Mezo
          software ("Software") directly from Mezo or indirectly through a Mezo
          authorized reseller or distributor (a "Reseller").
        </p>

        <p>
          Please read this EULA agreement carefully before completing the
          installation process and using the Mezo software. It provides a
          license to use the Mezo software and contains warranty information and
          liability disclaimers.
        </p>

        <p>
          If you register for a free trial of the Mezo software, this EULA
          agreement will also govern that trial. By clicking "accept" or
          installing and/or using the Mezo software, you are confirming your
          acceptance of the Software and agreeing to become bound by the terms
          of this EULA agreement.
        </p>

        <p>
          If you are entering into this EULA agreement on behalf of a company or
          other legal entity, you represent that you have the authority to bind
          such entity and its affiliates to these terms and conditions. If you
          do not have such authority or if you do not agree with the terms and
          conditions of this EULA agreement, do not install or use the Software,
          and you must not accept this EULA agreement.
        </p>

        <p>
          This EULA agreement shall apply only to the Software supplied by Mezo
          herewith regardless of whether other software is referred to or
          described herein. The terms also apply to any Mezo updates,
          supplements, Internet-based services, and support services for the
          Software, unless other terms accompany those items on delivery. If so,
          those terms apply.
        </p>

        <h3>License Grant</h3>

        <p>
          Mezo hereby grants you a personal, non-transferable, non-exclusive
          licence to use the Mezo software on your devices in accordance with
          the terms of this EULA agreement.
        </p>

        <p>
          You are permitted to load the Mezo software (for example a PC, laptop,
          mobile or tablet) under your control. You are responsible for ensuring
          your device meets the minimum requirements of the Mezo software.
        </p>

        <p>You are not permitted to:</p>

        <ul>
          <li>
            Edit, alter, modify, adapt, translate or otherwise change the whole
            or any part of the Software nor permit the whole or any part of the
            Software to be combined with or become incorporated in any other
            software, nor decompile, disassemble or reverse engineer the
            Software or attempt to do any such things
          </li>
          <li>
            Reproduce, copy, distribute, resell or otherwise use the Software
            for any commercial purpose
          </li>
          <li>
            Allow any third party to use the Software on behalf of or for the
            benefit of any third party
          </li>
          <li>
            Use the Software in any way which breaches any applicable local,
            national or international law
          </li>
          <li>
            use the Software for any purpose that Mezo considers is a breach of
            this EULA agreement
          </li>
        </ul>

        <h3>Intellectual Property and Ownership</h3>

        <p>
          Mezo shall at all times retain ownership of the Software as originally
          downloaded by you and all subsequent downloads of the Software by you.
          The Software (and the copyright, and other intellectual property
          rights of whatever nature in the Software, including any modifications
          made thereto) are and shall remain the property of Mezo.
        </p>

        <p>
          Mezo reserves the right to grant licences to use the Software to third
          parties.
        </p>

        <h3>Termination</h3>

        <p>
          This EULA agreement is effective from the date you first use the
          Software and shall continue until terminated. You may terminate it at
          any time upon written notice to Mezo.
        </p>

        <p>
          It will also terminate immediately if you fail to comply with any term
          of this EULA agreement. Upon such termination, the licenses granted by
          this EULA agreement will immediately terminate and you agree to stop
          all access and use of the Software. The provisions that by their
          nature continue and survive will survive any termination of this EULA
          agreement. This EULA was created by{" "}
          <a href="https://www.app-privacy-policy.com/app-eula-generator/">
            App EULA Template Generator from App-Privacy-Policy.com
          </a>{" "}
          for Mezo
        </p>

        <h3>Governing Law</h3>

        <p>
          This EULA agreement, and any dispute arising out of or in connection
          with this EULA agreement, shall be governed by and construed in
          accordance with the laws of <span class="country">us</span>.
        </p>
      </div>
      <Footer dark ref={footerRef} />
    </>
  );
}

export default EULA;
